// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  children: $(".children-pages-container.slider")
};

sliders.children.slick({
  dots: false,
  autoplay: true,
  slidesToShow: sliders.children.hasClass("fullwidth") ? 7 : 6
});
