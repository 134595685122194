import { isMobile } from "./helper";
import $ from "jquery";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import "select2/dist/js/select2.full.js";
import "select2/dist/css/select2.min.css";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  $(".wpcf7-select").select2({
    minimumResultsForSearch: -1,
    containerCssClass: "standard-select",
    dropdownCssClass: "standard-select-dropdown",
    dropdownAutoWidth: true,
    width: "element",
    placeholder: "Co Cię interesuje?"
  });

  toggleMenu();

  const fileInput = document.getElementById("file");

  if (fileInput) {
    fileInput.addEventListener("change", function() {
      const fileName = this.files[0].name;
      this.closest(".file-field").querySelector(
        ".file-placeholder"
      ).innerHTML = fileName;
    });
  }

  if (isMobile()) {
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
